<template>
    <v-card max-width="305" elevation="0" class="mx-auto">
        <v-card-title>{{$t('login.login').toUpperCase()}}</v-card-title>
    <v-card-text>
    <v-form v-model="valid" ref="form">
        <v-text-field :label="$t('login.username')" :rules="rules" v-model="input.username"></v-text-field>
        <v-text-field :label="$t('login.password')" :rules="rules" v-model="input.password"></v-text-field>
        
    </v-form>
    </v-card-text>
    <v-card-actions>
    <v-btn  v-on:click="login()" :disabled="!valid">{{$t('login.login')}}</v-btn>
    </v-card-actions>
    <v-snackbar :timeout="2000" v-model="snackbar" top color="red"> {{$t('login.invalid')}}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">{{$t('close')}}</v-btn>
      </template>
      </v-snackbar>
    </v-card>
</template>


<script>
    export default {
        name: 'Login',
        data() {
            return {
                input: {
                    username: "",
                    password: ""
                },
                valid: false,
                rules: [
                    value => !!value || 'Required.',
                    value => (value && value.length >= 1),
                ],
                snackbar: false
            }
        },
        computed: {
            validate () {
                return this.$refs.form.validate();
            }

        },
        methods: {
            login() {
                if(this.input.username != "" && this.input.password != "") {

                    let basic = Buffer.from(this.input.username + ":" + this.input.password).toString('base64');
                    this.getData(process.env.VUE_APP_SERVER_URL + 'login', basic)
                        .then(
                            (response) => {
                            
                            if(response.status == 200)
                            {   
                                let user = response.json();
                                user.basic = basic;
                                localStorage.setItem('user', JSON.stringify(user));
                                this.$emit("authenticated", true);
                                this.$router.replace({ name: "projects" });
                            }else{
                                this.snackbar = true
                            }
                        }
                    ).catch(
                        (error) => {
                        console.log(error);
                        }
                    );
                } else {
                    console.log("A username and password must be present");
                }
            },
        async getData(url, basic){

        const response = await fetch(url,
          {
            credentials: 'omit',
            method: 'GET',
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              "Authorization": 'Basic ' + basic
            }
          });
        return response;
    }
        }
    }
</script>