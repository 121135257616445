<template>
<v-card elevation="0">
  <v-card-title class="mx-auto"><v-icon @click="$router.push({name:'capex', params: {id: id}}).catch(()=>{})" color="indigo accent-3" class="mr-4">mdi-close</v-icon>{{$t('genericWork')}}</v-card-title>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card  elevation="0">
        <v-responsive class="pt-2" :aspect-ratio="1/2" max-height="450px">
        <v-col>
              <v-row >
                <v-text-field outlined v-model="work.description" value="" :label="$t('workDescription')"></v-text-field>
              </v-row>
              <v-row>
                  <v-select outlined :items="$t('workTypes')" item-text="label" item-value="value" v-model="work.workTypeId" :label="$t('workType')">
                  </v-select>
              </v-row>
              <v-row>
                  <v-select outlined :items="$t('riskTypes')" item-text="label" item-value="value" v-model="work.riskId" :label="$t('risk')">
                  </v-select>
              </v-row>
               <v-row>
                {{$t('picture')}}: 
              </v-row>
               <v-row>
                <v-avatar tile size="100" color="grey lighten-3" @click="upload()"><v-icon color="indigo accent-3">mdi-camera</v-icon></v-avatar>
                <v-badge class="pl-1" v-if="!!work.photo" cols="4" @click.native="remove()" bordered color="indigo accent-3" icon="mdi-close" overlap>
                <v-avatar tile size="100" color="grey"><v-img :src="work.photo" width="100%"></v-img></v-avatar>
                </v-badge>
              </v-row>
          </v-col>
        </v-responsive>
                <v-row>
          <v-col cols="4">
        <v-btn
          color="primary"
          disabled
        >
          {{$t('back')}}
        </v-btn>
          </v-col>
          <v-col cols="4">
        <v-btn
          color="primary"
          @click="e1 = 2"
        >
          {{$t('next')}}
        </v-btn>
        </v-col>
        </v-row>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card elevation="0">
          <v-responsive class="pt-2" :aspect-ratio="1/2" max-height="450px">
            <v-col>    
              <v-row >
                <v-text-field outlined v-model="work.costActual" value="" :label="$t('actualCost')"></v-text-field>
                </v-row>
                <v-row >
                <v-text-field outlined v-model="work.costEstimated" value="" :label="$t('estimatedCost')"></v-text-field>
                </v-row>
                <v-row >
                <v-text-field outlined v-model="work.costCorrected" value="" :label="$t('correctedCost')"></v-text-field>
                </v-row>
        </v-col>
          </v-responsive>
                  <v-row>
          <v-col cols="4">
        <v-btn
          color="primary"
          @click="e1 = 1"
        >
          {{$t('back')}}
        </v-btn>
          </v-col>
          <v-col cols="4">
        <v-btn
          color="primary"
          @click="saveWork()"
        >
          {{$t('save')}}
        </v-btn>
        </v-col>
        </v-row>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
              <v-file-input id="photoInput" v-show="false" v-model="image" accept="image/*" capture="environment" @change="change"/>
            <v-snackbar :timeout="3000" v-model="snackbar.status" top :color="snackbar.color"> {{$t(snackbar.message)}}
              <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar.status = false">{{$t('close')}}</v-btn>
              </template>
          </v-snackbar>
  </v-card>
</template>

<script>
import {indDB} from '../indexedDB.js'
import {doPost} from '../httpClient'
import {mutations} from '../store'
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "Defect",
  props: ['id','workId'],
  data: () => ({
    capex: {
      default: function() {
        return {
        name: "",
        description: "",
        location: "",
        clientId: 1,
        maintenance: "",
        resLifespam: "",
        unit: "",
        status: "",
        duration: "",
        price: ""
      }
      }
    },
    work: {
        id: undefined,
        type: "Defect",
        workTypeId: undefined,
        risk: "",
        capexId: undefined,
        description: "",
        defectType: "",
        costActual: undefined,
        costEstimated: undefined,
        costCorrected: undefined,
        severity: undefined,
        intensity: undefined,
        extent: undefined,
        photo: undefined,
        defect: {}
      },
    defectTypes: ['Fissures ou trous', 'Surchauffe locale d’éléments de la chaudière', 'Corrosion', 'Éléments devenus friables', 'Fuite', 'Coups'],
    isNew: true,
    image: undefined, 
    snackbar: {
      status: false,
      color: 'green',
      message: ""
    },
    e1: 1
    }),
  created: function(){

    if(this.id != null){

        indDB.capexes.get(parseInt(this.id)).then((cap) => {
        this.capex = cap;
        });

    if(this.workId != null){

      this.isNew = false;

        indDB.works.get(this.workId).then((work) => {
          this.work = work;
        });
    }

    }

      
  },
  methods: {
    saveWork() {

        if(this.isNew){
          this.work.id = uuidv4();
        }

        if(this.work.defect != undefined && this.work.defect.id == undefined){
          this.work.defect.id = uuidv4();
        }


        doPost( 'project/'+mutations.getProjectId()+'/capexes/'+this.capex.id+'/works', this.work)
          .then(response => {
                if(response.ok){
                    this.updateSnackbar("green","response.positiveMessage");
                    this.saveWorkOnDB();
                }else{
                  this.updateSnackbar("red","response.negativeMessage");
                    console.log("Capex with id "+this.capex.id+" received the following response: "+response.status);
                }
          })
          .catch(err => {
            this.updateSnackbar("orange","response.connectivityProblem");
            console.log(err);
            this.saveWorkOnDB();
      });
      
    
    },
    saveWorkOnDB() {

        indDB.transaction('rw',indDB.works, () =>
        {
          indDB.works.put(this.work);
        }).then(() => {
          console.log("Work has been saved.");
          this.isNew = false;
        }).catch(err => {
            console.error(err.stack);
        });
    
    },
    upload(){
        document.getElementById("photoInput").click();
    },
    remove(){

        this.work.photo = null;

        indDB.transaction('rw',indDB.works, () =>
        {
          indDB.works.put(this.work);
        }).then(() => {
          console.log("Photo has been removed.");
        }).catch(err => {
            console.error(err.stack);
        });

    },
    updateSnackbar(color, message){
              this.snackbar = {
                        status: true,
                        color: color,
                        message: message
                      }
    },
    change: function(){

      const reader = new FileReader()
            reader.readAsDataURL(this.image)
            reader.onload = e => {

            if(this.image != null){
                this.work.photo = e.target.result;
            }
      };

    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>