<template>
      <v-card  outlined @click="showCapex">
         <v-list-item two-line>
        <v-list-item-avatar tile size="80" class="my-0"><span>CAPEX</span></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{capex.name}}
            </v-list-item-title>
            <v-list-item-subtitle>ID: {{capex.id}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
        <v-card-text no-gutters>
          <v-row align="center"  class="mx-auto">
        <span>
          {{$tc('unit',1)}}: {{capex.unit}}
        </span>
        <span>|</span>
        <span>
          {{$tc('residualLifespan',1)}}: {{capex.residualLifespan}} {{$tc('year',1)}} / {{capex.duration}} {{$tc('year',1)}}
        </span>
        <span>
          |
        </span>
        <span>
          {{$tc('price',1)}}: {{priceFormatter}} €
        </span>
        <span>
          |
        </span>
        <span>
          <v-avatar :color="maintenance.color" size="10"></v-avatar>
          <span class="ml-1">{{maintenance.label}}</span>
        </span>
        <span>
          |
        </span>
        <span>{{$tc('statusLevel',1)}}:
          <span :class="nen.color" class="px-2 rounded-md ml-0">{{nen.label}}</span>
        </span>
        </v-row>
        </v-card-text>
      </v-card>
</template>

<script>
export default {
  name: "Capex",
  props: {
    capex: {
      required: true
    }
  },
  computed: {
    maintenance() {

      const list = this.$t("maintenanceLevels").filter(item => {return item.value == this.capex.maintenanceId})
      return list.length > 0 ? list[0] : {}
    },
    nen() {
      const list = this.$t("statusLevels").filter(item => {return item.value == this.capex.nenStatusId})
      return list.length > 0 ? list[0] : {}
    },
    priceFormatter: {
      get: function(){
        return this.capex.price / 100;
      },
      set: function(newPrice){
        this.capex.price =  newPrice * 100;
      }
    }
  },
  methods: {

    showCapex(){
      this.$router.push({name:'capex', params: {id: this.capex.id}}).catch(()=>{});
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  span {
    margin-left: 4px
  }
</style>