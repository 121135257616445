<template>
        <v-card elevation="0">
        <v-container>
            <v-row justify="space-around">
              <v-col
                v-for="(obj) in getItems()"
                :key="getKey(obj)"
                cols="12"
                md="4"
              >
              <object-preview v-bind:object="getPreviewObj(obj)" @on-change="onChange(obj)"></object-preview> 
              </v-col>
            </v-row>
        </v-container>
        </v-card>
</template>

<script>
import {indDB} from '../indexedDB.js'
import ObjectPreview from '../components/ObjectPreview.vue'
import {mutations} from '../store';

export default {
  name: "Capex",
  components: {ObjectPreview},
  props: ['projectId','buildingId','floorId','roomId'],
  data: () => ({
    projects: []
  }),
  watch: { '$route' (to) 
        { 
          //Ragionare sui queryParam
          if(to.name == 'projects' || to.path == '/'){  
            this.loadProjects();
          }
          mutations.reset();
        } 
  },
  created: function(){
    this.loadProjects();
  },
  computed:{
      filteredItems() {
        return this.reload();
    }
  },
  methods: {
    getItems() {
        return this.reload();
    },
    getKey(obj){
        return obj.type + obj.id;
    },
    getPreviewObj(obj){

        return {
          id: obj.id,
          description: obj.description,
          type: obj.type
        }
    },
    getIcon(){
      if(this.object.type == 'PROJECT'){
        return 'mdi-domain';
      }
      if(this.object.type == 'BUILDING'){
        return 'mdi-office-building';
      }
      if(this.object.type == 'FLOOR'){
        return 'mdi-floor-plan';
      }
      if(this.object.type == 'ROOM'){
        return 'mdi-google-classroom';
      }
      if(this.object.type == 'CAPEX'){
        return 'mdi-bank';
      }
    },
    loadProjects(){

      this.projects = [];
      indDB.hierarchyRoot.toArray().then((response) => {
        if(response.length == 1){
          response[0].children.forEach(el => this.projects.push(el));
        }else{
          console.log("Expected one hierarchy found ...");
        }
      });
    },
    onChange(obj){
      
      if(obj.type == 'ROOM' && this.projectId != null && this.buildingId != null && this.floorId != null){
          this.$router.push({name: 'allCapex', params: {id: this.projectId, builId: this.buildingId, floorId: this.floorId, roomId: obj.id}})
          return;
      }
      if(obj.type == 'FLOOR' && this.projectId != null && this.buildingId != null){
          this.$router.push({name: 'rooms', params: {id: this.projectId, builId: this.buildingId, floorId: obj.id}})
          return;
      }
      if(obj.type == 'BUILDING' && this.projectId != null){
          this.$router.push({name: 'floors', params: {id: this.projectId, builId: obj.id}})
          return;
      }
      if(obj.type == 'PROJECT'){
          this.$router.push({name: 'buildings', params: {id: obj.id}})
          return;
      }
        this.$router.push({path: '/projects'})

    },
    filter(list, id, type){

        if(list != undefined){
          var elements = list.filter((el) => {return el.id == id && el.type == type});
          if(elements.length == 1){
            return Object.assign([], elements[0]);
          }else{
            console.log("Expected one element only.");
            return list;
          }
        }else{
          return [];
        }
    },
    reload(){
      
      var projects = Object.assign([], this.projects);
      if(this.projectId == null){
          mutations.reset();
          return  this.projects;
      }else{
          var pro = this.filter(projects, this.projectId, 'PROJECT');
          mutations.setProject(this.projectId, pro.description);
        if(this.buildingId == null){
          return pro.children;
        } else{
            var building = this.filter(pro.children, this.buildingId, 'BUILDING');
            mutations.setBuilding(this.buildingId, building.description);
            if(this.floorId == null){
                return  building.children;
            }else{
                  var floor = this.filter(building.children, this.floorId, 'FLOOR');
                  mutations.setFloor(this.floorId, floor.description);
                  if(this.roomId == null){
                      return floor.children;
                  }else{
                      var room =  this.filter(floor.children, this.roomId, 'ROOM');
                      mutations.setRoom(this.roomId, room.description);
                      return room.children;
                  }
      }
        }
      }
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-textarea {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
</style>