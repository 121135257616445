<template>
    <v-row >  
        <v-col sm="12" lg="6" class="mx-auto">
        <v-card class="pt-2" elevation="0">  
            <v-img @click="$router.push({name:'photo',params:{workId: workId}})" v-if="!!work.photo" height="200" :src="work.photo" width="100%"></v-img>
            <v-card-title>{{workType.label}}</v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="4">
                  {{$t('workDescription')}}:
                </v-col>
                <v-col cols="8">
                  {{work.description}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  {{$t('risk')}}:
                </v-col>
                <v-col cols="8" class="font-weight-black">
                  {{risk.label}}
                </v-col>
              </v-row>
              <v-row v-if="!!work.defect">
                <v-col cols="4">
                  {{$t('defectDescription')}}:
                </v-col>
                <v-col cols="8">
                  {{work.defect.expertProvidedDescription}}
                </v-col>
              </v-row>
              <v-row v-if="!!work.defect">
                <v-col cols="4">
                  {{$t('defectType')}}:
                </v-col>
                <v-col cols="8" class="font-weight-black">
                  {{work.defect.typeDefectId}}
                </v-col>
              </v-row>
              <v-row>
                <p/>
              </v-row>
              <v-row v-if="!!work.defect">
                <v-col cols="4">
                  {{$tc('severity',2)}}:
                </v-col>
                <v-col cols="8">
                  <div>
                    <v-rating v-model="work.defect.severity">
                      <template v-slot:item="props">
                          <v-avatar size="30" v-if="work.defect.severity == props.index + 1" tile :color="getColor(props.index + 1)" class="white--text mr-1">{{ props.index + 1}}</v-avatar>
                          <v-avatar size="30" v-if="work.defect.severity != props.index + 1" tile :color="getColor(props.index + 1)" class="mr-1" height="3px"></v-avatar>
                      </template>
                    </v-rating>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!!work.defect">
                <v-col cols="4">
                  {{$tc('intensity',2)}}:
                </v-col>
                <v-col cols="8">
                  <div>
                    <v-rating v-model="work.defect.intensity">
                      <template v-slot:item="props">
                          <v-avatar size="30" v-if="work.defect.intensity == props.index + 1" tile :color="getColor(props.index + 1)" class="white--text mr-1">{{ props.index + 1}}</v-avatar>
                          <v-avatar size="30" v-if="work.defect.intensity != props.index + 1" tile :color="getColor(props.index + 1)" class="mr-1" height="3px"></v-avatar>
                      </template>
                    </v-rating>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="!!work.defect">
                <v-col cols="4">
                  {{$tc('extent',2)}}:
                </v-col>
                <v-col cols="8">
                  <div>
                    <v-rating v-model="work.defect.extent">
                      <template v-slot:item="props">
                          <v-avatar size="30" v-if="work.defect.extent == props.index + 1" tile :color="getColor(props.index + 1)" class="white--text mr-1">{{ props.index + 1}}</v-avatar>
                          <v-avatar size="30" v-if="work.defect.extent != props.index + 1" tile :color="getColor(props.index + 1)" class="mr-1" height="3px"></v-avatar>
                      </template>
                    </v-rating>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <p/>
              </v-row>
              <v-row>
                <v-col cols="4">
                  {{$t('actualCost')}}:
                </v-col>
                <v-col cols="8" class="font-weight-black">
                  {{work.costActual}} €
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  {{$t('estimatedCost')}}:
                </v-col>
                <v-col cols="8" class="font-weight-black">
                  {{work.costEstimated}} €
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  {{$t('correctedCost')}}:
                </v-col>
                <v-col cols="8" class="font-weight-black">
                  {{work.costCorrected}} €
                </v-col>
              </v-row>
              
            </v-card-subtitle>
      <v-card-actions>
          <v-row>
                <v-col cols="4">
                  <v-btn block color="grey lighten-2" @click="dialog = true"><v-icon color="indigo accent-3">mdi-delete</v-icon></v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn block color="indigo accent-3 white--text" @click="showWork">Edit</v-btn>
                </v-col>
          </v-row>
      </v-card-actions>        


      <v-dialog v-model="dialog" max-width="600">
               <v-card>
        <v-card-title>
          {{$t('areYouSure')}}
        </v-card-title>
        <v-card-text>{{$t('cannotBeUndone')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="indigo accent-3"
            text
            @click="deleteWork()"
          >
           {{$t('delete')}}
          </v-btn>
          <v-btn
            color="indigo accent-3"
            text
            @click="dialog = false"
          >
            {{$t('close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-dialog>
        </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {indDB} from '../indexedDB.js'

export default {
  name: "Work",
  props: ['id','workId'],
  data: () => {
    return {
      icon: null,
      dialog: false,
      colors: {
        1: 'light-green darken-4',
        2: 'indigo darken-3',
        3: 'cyan darken-3',
        4: 'amber accent-4',
        5: 'deep-orange darken-4' 
      },
      work: {},
      capex: {}
    }
  },

  mounted: function(){

    if(this.id != null){

        indDB.capexes.get(parseInt(this.id)).then((cap) => {
        this.capex = cap;
        });

        indDB.works.get(this.workId).then((work) => {
          this.work = work;
        });
    }

      
  },
  computed: {
    workType() {
        const list = this.$t("workTypes").filter(item => {return item.value == this.work.workTypeId})
        return list.length > 0 ? list[0] : {}
    },
    risk() {
        const list = this.$t("riskTypes").filter(item => {return item.value == this.work.riskId})
        return list.length > 0 ? list[0] : {}
    },
  },
  methods: {
    getColor(level){

        return this.colors[level];
    },
    showWork(){

      if(7 == this.work.workTypeId){
        this.$router.push({name:'replacement', params: {id: parseInt(this.id), workId: this.work.id}}).catch(()=>{});
      }else if(this.work.defect != null){
        this.$router.push({name:'defect', params: {id: parseInt(this.id), workId: this.work.id}}).catch(()=>{});
      }else{
        this.$router.push({name:'genericWork', params: {id: parseInt(this.id), workId: this.work.id}}).catch(()=>{});
      }
    },
    deleteWork() {

        indDB.transaction('rw',indDB.works, () =>
        {
          indDB.works.delete(this.work.id);
          
        }).then(() => {
          console.log("Work has been deleted.");
          this.$router.push({name:'capex', params: {id: this.capex.id}}).catch(()=>{});
        }).catch(err => {
            console.error(err.stack);
        });

        this.dialog = false;
    
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>