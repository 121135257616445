export function authHeader() {

    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.basic) {
        return  'Basic ' + user.basic ;
    } else {
        return '';
    }
}

export async function doPost(path, body){

    const response = await fetch(process.env.VUE_APP_SERVER_URL + path,
        {
          credentials: 'omit',
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": authHeader()
          }
        });
      return response;

}

export async function doPut(path, body){

    const response = await fetch(process.env.VUE_APP_SERVER_URL + path,
        {
          credentials: 'omit',
          method: 'PUT',
          body: JSON.stringify(body),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": authHeader()
          }
        });
      return response;

}

export async function doGet(path){

  const response = await fetch(process.env.VUE_APP_SERVER_URL + path,
      {
        credentials: 'omit',
        method: 'GET',
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": authHeader()
        }
      });
    return response;

}