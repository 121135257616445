<template>
  <v-row>
    <v-col sm="12" lg="6" class="mx-auto">
  <v-card elevation="0">
        <v-sheet class="pa-4">
      <v-text-field 
        class = "indigo accent-3"
        v-model="search"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>
    </v-sheet>
  <v-treeview :items="projects" activatable hoverable shaped
      return-object
      item-key="emsId"
      item-text="description"
      :search = 'search'
      :filter = 'filter'
      @update:open = 'readTree'
  >
    <template v-slot:label="{ item }">
        <div @click="getElement(item)">{{item.description}}</div>
    </template>
    <template v-slot:append="{ item }">
        <div @click="getElement(item)"> <code class="ml-3 blue lighten-4" v-if="item.type != 'PROJECT'">{{item.capexes}} </code></div>
    </template>
  </v-treeview>
    </v-card>
    </v-col>
   </v-row> 
</template>

<script>
import {indDB} from '../indexedDB.js'
import {mutations} from '../store';

export default {
  name: "Capex",
  props: ['projectId','buildingId','floorId','roomId'],
  data: () => ({
    projects: [],
    search: '',
    caseSensitive: false,
    tree: []
  }),
  mounted: function(){
    this.loadProjects();
  },
  computed: {
      filter () {
        return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
      }
    },
  methods: {
  readTree(val){
    this.tree = val;
  },
  getElement(elem){
        if(elem.capexes > 0){
          mutations.updateTree(this.tree.concat(elem));
          this.$router.push({name:'capexes', params: {assetId: elem.emsId}});
        }
    },
    loadProjects(){

      var list = [];
      indDB.hierarchyRoot.toArray().then((response) => {
        if(response.length == 1){
          response[0].children.forEach(el => list.push(el));
          list.forEach(el => { this.projects.push(this.readChildren(el)) });
        }else{
          console.log("Expected one hierarchy found ...");
        }
      });
    },
    readChildren(obj){
    
        const children = [];
        var count = 0;
        if(obj.children){
          for(const i in obj.children){
            const asset = obj.children[i];
            if(asset.type == 'CAPEX'){
              count = count +1;
            }else{
              children.push(this.readChildren(asset));                   
            }
          }

        }

        obj.capexes = count;
        obj.children = children
        return obj;
    }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-textarea {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
</style>