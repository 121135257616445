import Dexie from 'dexie';
let iDB = new Dexie('Capexes');

//clientId, capexId are properties we can use in a where query
// https://dexie.org/docs/Tutorial/Design#database-versioning
iDB.version(1).stores({
  capexes: 'id, clientId',
  works: 'id, capexId',
  hierarchyRoot: 'id, workId, name'
});

iDB.version(2).stores({
  capexes: 'id, containingAssetId',
  works: 'id, capexId',
  hierarchyRoot: 'emsId'
});

export let indDB = iDB;
