<template>
    <v-row >  
        <v-col sm="12" lg="6" class="mx-auto">
        <v-card class="pt-2" elevation="0">  
           <capex-preview :capex="capex" class="mx-auto"></capex-preview>
            <v-row class="mt-1">  
              <v-col>
              <v-btn block @click="addWork()" color="indigo accent-3 white--text">{{$tc('work',1)}}</v-btn>
              </v-col>
            </v-row>
           <v-card-title> {{$tc('work',2)}} <code class="text-subtitle-1 grey lighten-1 py-0 ml-2">{{works.length}}</code></v-card-title>
          <v-row>
          <v-col
            v-for="(work) in works"
            :key="work.id"
            cols="12"
          >
          <work-preview class="mx-0" :work="work" :capexId="capex.id"></work-preview>
          </v-col>
          </v-row>
        <v-snackbar :timeout="3000" v-model="snackbar.status" top :color="snackbar.color"> {{$t(snackbar.message)}}
            <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.status = false">{{$t('close')}}</v-btn>
            </template>
          </v-snackbar>
        </v-card>  
        </v-col>
    </v-row>
</template>

<script>
import {indDB} from '../indexedDB.js'
import CapexPreview from '../components/CapexPreview.vue'
import WorkPreview from '../components/WorkPreview.vue'
import {mutations} from '../store'
export default {
  name: "Capex",
  components: {WorkPreview, CapexPreview},
  props: ['id'],
  watch: { 
      id: function(newVal, oldVal) {
          
          if(newVal != oldVal && newVal == null){
            this.isNew = true;
            this.capex = {
                            name: "",
                            description: "",
                            location: "",
                            clientId: 1,
                            maintenance: undefined,
                            residualLifespan: undefined,
                            unit: "",
                            status: "",
                            duration: undefined,
                            price: undefined
                          }
            this.works = [];
          }
        }
  },
  computed: {
    priceFormatter: {
      get: function(){
        return this.capex.price / 100;
      },
      set: function(newPrice){
        this.capex.price =  newPrice * 100;
      }
    }
  },
  data: () => ({
    works: [],
    icon: mutations.getCapex().icon,
    capex: {
        name: "",
        description: "",
        location: "",
        clientId: 1,
        maintenance: undefined,
        residualLifespan: undefined,
        unit: "",
        status: "",
        duration: undefined,
        price: undefined
      },
    snackbar: {
      status: false,
      color: 'green',
      message: ""
    }
  }),

  created: function(){

    this.isNew = this.id == null

    if(!this.isNew){
        indDB.capexes.get(parseInt(this.id)).then((cap) => {
        this.capex = cap;
      });

      indDB.works.where('capexId').equals(parseInt(this.id)).each((work) => {
          this.works.push(work);
      });
    }
  },
  methods: {
    updateSnackbar(color, message){
              this.snackbar = {
                        status: true,
                        color: color,
                        message: message
                      }
    },
    addWork() {
      this.$router.push({name: 'works', params: {id: this.capex.id}})
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-textarea {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
</style>