<template>
<v-container>
  <v-row>
    <v-col sm="10" lg="4" class="mx-auto">
              <v-sheet class="px-4">
          <v-text-field
            v-model="search"
            class = "grey lighten-1"
            prepend-inner-icon="mdi-magnify"
            dark
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
    </v-col>
  </v-row>
  <v-row>
    <v-col sm="12" lg="12" class="px-0 mx-auto">
        <v-card elevation="0" class="pt-2">
        <v-container fluid>
          <v-row>
                      <v-col
            v-for="(capex) in filter"
            :key="capex.id"
            sm="12" lg="6" class="mx-auto"
          >
          <object-preview v-bind:capex="capex" ></object-preview>
                      </v-col>
          </v-row>
        </v-container>
        </v-card>
        </v-col>
   </v-row> 
  </v-container>
</template>

<script>
import {indDB} from '../indexedDB.js'
import ObjectPreview from '../components/ObjectPreview.vue'

export default {
  name: "Capex",
  components: {ObjectPreview},
  props: ['assetId'],
  data: () => ({
    capexes: [],
    name: "",
    showTable: true,
    search: undefined,
  }),
  computed: {
      filter () {
        return this.search ? this.capexes.filter(cap => {return cap.name.toLowerCase().includes(this.search.toLowerCase())}) : this.capexes;
      },
    },
  created: function(){

      indDB.capexes.where('containingAssetId').equals(parseInt(this.assetId)).each((capex) => {
          this.capexes.push(capex);
      });

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>