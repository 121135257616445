import Vue from "vue";

export const store = Vue.observable({
    state: {
        projectId: undefined,
        breadcrumb: ''
    }
}    
)

export const mutations = {

    clean(){
        store.state.projectId = undefined;
        store.state.breadcrumb = '';
        sessionStorage.setItem('state',  JSON.stringify(store.state));
    },
    reset(){
        sessionStorage.setItem('state',  JSON.stringify(store.state));
    },
    updateTree(tree){
        store.state.breadcrumb = this.computeBreadCrumb(tree);
        const filtered = tree.filter(val => {return 'PROJECT' == val.type}).map(val => {return val.emsId});
        store.state.projectId =  filtered.length > 0 ? filtered[0] : undefined;
        
        sessionStorage.setItem('state',  JSON.stringify(store.state));
    },
    computeBreadCrumb(tree){
          return  tree ? tree.map(val => {return val.description}).reduce(this.concat) : '';
      },
    concat(val1, val2){
          return val1 + ' / ' + val2;
      },
    getProjectId(){
        return store.state.projectId;
    },
    readStorage(){
        const state = JSON.parse(sessionStorage.getItem('state'));
        if(state){
            store.state.breadcrumb = state.breadcrumb;
            store.state.projectId = state.projectId;
        }
    },
    getCapex(){
        return {
            icon: 'mdi-bank'
        }
    }


}