<template>
<v-overlay :absolute="absolute" opacity="1" overlay="false">
  <v-card elevation="0" max-width="374">
      <v-icon @click="close">mdi-close</v-icon>
      <v-img height="100%" :src="work.photo" max-width="400"></v-img>
  </v-card>
</v-overlay>
</template>

<script>
import {indDB} from '../indexedDB.js'

export default {
  name: 'Camera',
  props: ['workId'],
  data: () => ({
    work: {
      photo: undefined
    },
    absolute: true
  }),
  created: function(){
    
        indDB.works.get(this.workId).then((work) => {
          this.work = work;
        });
  },
  methods: {
    close(){
        this.$router.go(-1);
    }
}
}
</script>

<style>
</style>