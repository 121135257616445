<template>
      <v-card  @click="showWork()" outlined>
            <v-list-item two-line>
              <v-list-item-avatar v-if="!!work.photo" tile size="100" color="grey"><v-img :src="work.photo" width="100%"></v-img></v-list-item-avatar>
              <v-list-item-avatar v-if="!work.photo" tile size="70" color="grey"></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">
                          {{workType.label}}
                </v-list-item-title>
                <v-list-item-subtitle>
                <div class="overline mb-1">
                    {{$t('actualCost')}}: {{work.costActual}} €
                </div>
                <div class="grey--text">
                  {{$t('risk')}}: {{risk.label}}
                </div>
                <div class="grey--text mb-1">
                    {{$tc('year',2)}}: {{work.year}}
                </div>
                <div v-if="!!work.defect">
                   <span class="py-0 px-1 white--text mr-1 rounded-md" :class="getColor(work.defect.severity)">{{$tc('severity',1)}}{{work.defect.severity}}</span>
                   <span class="py-0 px-1 white--text mr-1 rounded-md" :class="getColor(work.defect.intensity)">{{$tc('intensity',1)}}{{work.defect.intensity}}</span>
                   <span class="py-0 px-1 white--text mr-1 rounded-md" :class="getColor(work.defect.extent)">{{$tc('extent',1)}}{{work.defect.extent}}</span>
                </div>
                </v-list-item-subtitle>
              </v-list-item-content>
           </v-list-item>
        </v-card>
</template>

<script>
export default {
  name: "Work",
  props: {
    work: {
      required: true,
      default: {

      }
    },
    capexId: undefined
  },
  data: () => {
    return {
      colors: {
        1: 'light-green darken-4',
        2: 'indigo darken-3',
        3: 'cyan darken-3',
        4: 'amber accent-4',
        5: 'deep-orange darken-4' 
      },
    }
  },

  computed: {
    workType() {
        const list = this.$t("workTypes").filter(item => {return item.value == this.work.workTypeId})
        return list.length > 0 ? list[0] : {}
    },
    risk() {
        const list = this.$t("riskTypes").filter(item => {return item.value == this.work.riskId})
        return list.length > 0 ? list[0] : {}
    },
  },
  methods: {
    getColor(level){
        return this.colors[level];
    },
    showWork(){      
      this.$router.push({name:'workDetails', params: {id: parseInt(this.capexId), workId: this.work.id}}).catch(()=>{});

    }

  }
};
</script>

<style scoped>
</style>