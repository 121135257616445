<template>
        <v-card elevation="0">
            <v-card-title>{{capex.name}}</v-card-title>
     <v-container>
     <v-form v-model="valid" ref="form">
           <v-col>
                <v-row v-if="isNew">
                <v-text-field disabled type="text" v-model="capex.name" value="" :label="$t('name')" :rules="notEmptyRule" required></v-text-field>
                </v-row>
             <v-row>
                <v-col sm="10" lg="4"> 
                  <v-select outlined :items="$t('maintenanceLevels')" item-text="label" item-value="value" v-model="capex.maintenanceId" :label="$tc('maintenanceLevel',2)">
                  </v-select>
                </v-col>
              </v-row> 
       <!--         <v-row>
                  <v-col sm="10" lg="4">
                      <v-slider
                        v-model="capex.maintenanceId"
                        :label="$t('maintenanceLevel')"
                        :max="3"
                        :min="1"
                        step="1"
                        ticks="always"
                        tick-size="1"
                        thumb-label="always"
                        thumb-size="30"
                      >
                  <template v-slot:thumb-label>
                       {{label($t('maintenanceLevels')).substring(0,3)}}
                  </template>
                      </v-slider>
                  </v-col>  
              </v-row> -->
               <v-row>
                 <v-col sm="10" lg="4"> 
                <v-text-field outlined v-model="capex.residualLifespan" value="" :label="$tc('residualLifespan',2)"></v-text-field>
                </v-col>
                </v-row>
                <v-row >
                  <v-col sm="10" lg="4"> 
                <v-text-field outlined v-model="capex.unit" value="" :label="$tc('unit',2)"></v-text-field>
                </v-col>
                </v-row>
                <v-row >
                  <v-col sm="10" lg="4"> 
                  <v-select outlined :items="$t('statusLevels')" item-text="label" item-value="value" v-model="capex.nenStatusId" :label="$tc('statusLevel',2)">
                  </v-select>
                  </v-col>
                </v-row>
                <v-row >
                  <v-col sm="10" lg="4"> 
                <v-text-field outlined v-model="capex.duration" value="" :label="$t('duration')"></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                  <v-col sm="10" lg="4"> 
                <v-text-field outlined v-model="priceFormatter" value="" :label="$tc('price',2)"></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                  <v-col sm="10" lg="4"> 
                  <v-text-field outlined v-model="capex.dimension" value="" :label="$t('dimension')"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="10" lg="4"> 
                  <v-text-field outlined v-model="capex.commissioningYear" value="" :label="$t('commissioningYear')"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="10" lg="4"> 
                   <v-switch color="indigo" v-model="capex.compliantRegulations" :label="$t('compliantRegulations')"></v-switch>
                   </v-col>
                </v-row>
                <v-row>
                  <v-col sm="10" lg="4"> 
                  <v-switch color="indigo" v-model="capex.applicableRegulations" :label="$t('applicableRegulations')"></v-switch>
                  </v-col>
                </v-row>

                  </v-col>
              <v-card-text>
            <v-row>
            <v-col cols="4">
              </v-col>
            <v-col cols="8">
              <v-btn block color="indigo accent-3 white--text" @click="saveCapex()">{{$t('save')}}</v-btn>
              </v-col>
          </v-row>
          </v-card-text>
          </v-form>
               </v-container>
        <v-snackbar :timeout="3000" v-model="snackbar.status" top :color="snackbar.color"> {{$t(snackbar.message)}}
            <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="snackbar.status = false">{{$t('close')}}</v-btn>
            </template>
          </v-snackbar>
        </v-card>
</template>

<script>
import {indDB} from '../indexedDB.js'
import {mutations} from '../store'
import {doPut} from '../httpClient'
export default {
  name: "Capex",
  props: ['id'],
  watch: { 
      id: function(newVal, oldVal) {
          
          if(newVal != oldVal && newVal == null){
            this.isNew = true;
            this.capex = {
                            name: "",
                            description: "",
                            location: "",
                            clientId: 1,
                            maintenance: undefined,
                            residualLifespan: undefined,
                            unit: "",
                            status: "",
                            duration: undefined,
                            price: undefined
                          }
            this.works = [];
          }
        }
  },
  computed: {
    priceFormatter: {
      get: function(){
        return this.capex.price / 100;
      },
      set: function(newPrice){
        this.capex.price =  newPrice * 100;
      }
    }
  },
  data: () => ({
    isNew: false,
    valid: true,
    works: [],
    icon: mutations.getCapex().icon,
    capex: {
        name: "",
        description: "",
        location: "",
        clientId: 1,
        maintenance: undefined,
        residualLifespan: undefined,
        unit: "",
        status: "",
        duration: undefined,
        price: undefined
      },
    notEmptyRule: [v => !!v || 'a value is required'],
    snackbar: {
      status: false,
      color: 'green',
      message: ""
    }
  }),

  created: function(){

    this.isNew = this.id == null

    if(!this.isNew){
        indDB.capexes.get(parseInt(this.id)).then((cap) => {
        this.capex = cap;
      });

      indDB.works.where('capexId').equals(parseInt(this.id)).each((work) => {
          this.works.push(work);
      });
    }
  },
  methods: {
    label(labels){
      return labels.filter(lab => {return lab.value == this.capex.maintenanceId})[0].label
    },
    saveCapex() {

      if(this.$refs.form.validate()){

        doPut( 'project/'+mutations.getProjectId()+'/capexes/'+this.capex.id, this.capex)
          .then(response => {
                if(response.ok){
                    this.updateSnackbar("green","response.positiveMessage");
                    this.saveCapexOnDB();
                }else{
                  this.updateSnackbar("red","response.negativeMessage");
                    console.log("Capex with id "+this.capex.id+" received the following response: "+response.status);
                }
          })
          .catch(err => {
            this.updateSnackbar("orange","response.connectivityProblem");
            console.log(err);
            this.saveCapexOnDB();
      });
      }
    
    },
    saveCapexOnDB(){
          indDB.transaction('rw',indDB.capexes, () =>
          {
            indDB.capexes.put(this.capex);
          }).catch(err => {
              console.error(err.stack);
          });
    },
    updateSnackbar(color, message){
              this.snackbar = {
                        status: true,
                        color: color,
                        message: message
                      }
    },
    updateWorks(){

      this.works = [];
      indDB.works.where('capexId').equals(parseInt(this.id)).each((work) => {
          this.works.push(work);
      });


    },
    addWork() {
      this.$router.push({name: 'works', params: {id: this.capex.id}})
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.v-textarea {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }
</style>