import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import { routes } from "./routes"
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(VueRouter);

const myRouter = new VueRouter({
  routes: routes
});

myRouter.beforeEach((to, from, next) => {

  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();

})


new Vue({
  vuetify,
  router: myRouter,
  i18n,
  render: h => h(App)
}).$mount('#app')

