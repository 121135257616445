import Capex from './components/Capex.vue'
import CapexList from './components/CapexList.vue'
import Works from './components/Works.vue'
import ReplacementWork from './components/ReplacementWork.vue'
import CapexHome from './components/CapexHome.vue'
import Hierarchy from './components/Hierarchy.vue'
import Login from './components/Login.vue'
import WorkDetails from './components/WorkDetails.vue'
import DefectForm from './components/DefectForm.vue'
import GenericWork from './components/GenericWork.vue'
import TreeView from './components/TreeView.vue'
import Photo from './components/Photo.vue'

export const routes = [
  { path: '*', redirect: '/' },
  { name: "login", path: '/login', component: Login },
  { path: '/', component: TreeView},
  { name: 'tree', path: '/tree', component: TreeView},
  { path: '/capex/new', component: Capex },
  { name: 'photo', path: '/work/:workId/photo', component: Photo, props: route => ({ workId: route.params.workId }) },
  { name: 'capex', path: '/capex/:id', component: CapexHome, props: route => ({ id: route.params.id }) },
  { name: 'capexForm', path: '/capex/:id/edit', component: Capex, props: route => ({ id: route.params.id }) },
  { name: 'asset', path: '/asset/:id', component: Hierarchy, props: route => ({ id: route.params.id }) },
  { name: 'works', path: '/capex/:id/works', component: Works, props: route => ({ id: route.params.id }) },
  { name: 'capexes', path: '/:assetId/capex', component: CapexList, props: route => ({ assetId: route.params.assetId }) },
  { name: 'replacementNew', path: '/capex/:id/replacement', component: ReplacementWork, props: route => ({ id: route.params.id }) },
  { name: 'defectNew', path: '/capex/:id/defect', component: DefectForm, props: route => ({ id: route.params.id }) },
  { name: 'genericWorkNew', path: '/capex/:id/genericWork', component: GenericWork, props: route => ({ id: route.params.id }) },
  { name: 'replacement', path: '/capex/:id/replacement/:workId', component: ReplacementWork, props: route => ({ id: route.params.id, workId: route.params.workId }) },
  { name: 'defect', path: '/capex/:id/defect/:workId', component: DefectForm, props: route => ({ id: route.params.id, workId: route.params.workId }) },
  { name: 'genericWork', path: '/capex/:id/genericWork/:workId', component: GenericWork, props: route => ({ id: route.params.id, workId: route.params.workId }) },
  { name: 'workDetails', path: '/capex/:id/work/:workId', component: WorkDetails, props: route => ({ id: route.params.id, workId: route.params.workId }) }
];