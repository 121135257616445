<template>
  <v-app>
    <v-app-bar dark dense app elevation="1" class="indigo accent-3">
      <v-app-bar-nav-icon class="mx-auto" style="cursor: pointer" @click="tree()"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn  @click="sync()" text class="white--text">sync</v-btn>
        <v-btn  @click="change()" text class="white--text">{{val}}</v-btn>
        <v-btn v-if="authenticated" @click="logout()" text class="white--text">Logout</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-sheet v-if="!!breadcrumb" class="pa-4">
          <span class="body-2 grey--text"><v-icon color="indigo accent-3" left style="cursor: pointer" @click="$router.go(-1)">mdi-arrow-left-thick</v-icon>{{computeBread}}</span>
        </v-sheet>
      <router-view @authenticated="setAuthenticated" />
      </v-container>
    </v-main>
    <v-snackbar :timeout="3000" v-model="snackbar.status" top :color="snackbar.color"> {{$t(snackbar.message)}}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.status = false">{{$t('close')}}</v-btn>
      </template>
    </v-snackbar>
    </v-app>
</template>

<script>
import {indDB} from './indexedDB.js'
import {doGet} from './httpClient'
import {store, mutations} from './store';
//import TreeView from './components/TreeView.vue';

export default {
  name: 'App', 
  data: () => ({
   authenticated: false,
   langs: ['en', 'fr'],
   val: undefined,
   snackbar: {
      status: false,
      color: 'green',
      message: ""
    },
    breadcrumb: store.state.breadcrumb,
  }),
  mounted: function(){
    this.val = this.$i18n.locale;
    mutations.readStorage();   
    this.breadcrumb = store.state.breadcrumb;
    this.authenticated = localStorage.getItem('user') != null;
    if(!this.authenticated) {
      this.$router.replace({ name: "login" }).catch(()=>{});
    }
  },
  watch: {
    '$route': function(to) {
      if(to.path == '/' || to.path == '/tree'){
        mutations.clean();
      }
      this.breadcrumb = store.state.breadcrumb;
    }
  },
  computed: { 
    computeBread(){
        var breadcrumb = this.breadcrumb;

        return breadcrumb.length > 40 ? '...' + breadcrumb.substring(breadcrumb.length - 40, breadcrumb.length) : breadcrumb
    }, 
  },
  methods: {
    tree(){
      mutations.reset(); 
      this.$router.push({name: 'tree'}).catch(() => {})
    },
    changeStatus(){
        this.tree = this.tree ? false : true;
    },
    change(){
      this.val = this.val == 'en' ? 'fr': 'en';
      this.$i18n.locale = this.val;
    },
    setAuthenticated(status) {
      this.authenticated = status;
    },
    logout() {
        this.authenticated = false;
        mutations.reset();
        localStorage.removeItem('user');
        this.$router.replace({ name: "login" }).catch(()=>{});
    },
    isEmpty(str) {
        return (!str || 0 === str.length);
    },
    sync(){
      // "C:\Program Files (x86)\Google\Chrome\Application\chrome.exe" --disable-web-security --user-data-dir="C:\User"
       doGet('hierarchy')
          .then(response => {
              
              if(!response.ok){
                  this.updateSnackbar("red","response.negativeMessage");
                    console.log("Error during sync.");
              }else{
                return response.json();
              }
        }
      ).then((response) => {

              indDB.hierarchyRoot.clear();
              indDB.capexes.clear();
              indDB.works.clear();      
              
              indDB.transaction('rw',indDB.hierarchyRoot, () =>
                  {
                    console.log(response.hierarchyRoot);
                    indDB.hierarchyRoot.put(response.hierarchyRoot);
                  }).then(() => {
                    console.log("Hierarchy root has been updated.");
                  }).catch(err => {
                      console.error(err.stack);
                  });

              indDB.transaction('rw',indDB.works, () =>
                  {
                      response.capexes.forEach((capex) => {
                          capex.works.forEach((work) => {
                                work.capexId = capex.id;
                                indDB.works.put(work);
                          })
                      });
                  
                  }).then(() => {
                    console.log("Works have been saved.");
                  }).catch(err => {
                      console.error(err.stack);
                  });

              indDB.transaction('rw',indDB.capexes, () =>
                  {
                      response.capexes.forEach((capex) => {
                          capex.works = undefined;
                          indDB.capexes.put(capex);
                      });
                  
                  }).then(() => {
                    console.log("Capexes have been saved.");
                  }).catch(err => {
                      console.error(err.stack);
                  });
                  
              this.updateSnackbar("green","response.positiveMessage");      
              this.$router.replace('/').catch(()=>{});


      })
      .catch(
        (error) => {
          console.log(error);
          this.updateSnackbar("orange","response.connectivityProblem");
        }
      );
      
    },
  updateSnackbar(color, message){
              this.snackbar = {
                        status: true,
                        color: color,
                        message: message
                      }
    },
  },

};
</script>
