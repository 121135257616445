<template>
        <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header v-slot="{ open }" class="pl-1">  
      <v-card  elevation="0">
        <v-list-item two-line>
        <v-list-item-avatar tile size="80" class="my-0"><span>CAPEX</span></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{capex.name}}
            </v-list-item-title>
            <v-list-item-subtitle>ID: {{capex.id}}</v-list-item-subtitle>
          </v-list-item-content>
      </v-list-item>
        <v-card-text v-if="!open" no-gutters>
          <v-row align="center"  class="mx-auto">
        <span>
          {{$tc('unit',1)}}: {{capex.unit}}
        </span>
        <span>|</span>
        <span>
          {{$tc('residualLifespan',1)}}: {{capex.residualLifespan}} {{$tc('year',1)}} / {{capex.duration}} {{$tc('year',1)}}
        </span>
        <span>
          |
        </span>
        <span>
          {{$tc('price',1)}}: {{priceFormatter}} €
        </span>
        <span>
          |
        </span>
        <span>
          <v-avatar :color="maintenance.color" size="10"></v-avatar>
          <span class="ml-1">{{maintenance.label}}</span>
        </span>
        <span>
          |
        </span>
        <span>{{$tc('statusLevel',1)}}:
          <span :class="nen.color" class="px-2 rounded-md ml-0">{{nen.label}}</span>
        </span>
        </v-row>
        </v-card-text>
        <v-card-text v-if="open">
              <v-row>
                <v-col cols="6">
                  {{$tc('unit',2)}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                  {{capex.unit}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  {{$tc('residualLifespan',2)}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                  {{capex.residualLifespan}} {{$tc('year',1)}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  {{$tc('duration',2)}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                  {{capex.duration}} {{$tc('year',1)}}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  {{$tc('price',2)}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                  {{priceFormatter}} €
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  {{$tc('maintenanceLevel',2)}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                      <v-avatar :color="maintenance.color" size="10"></v-avatar>
                            <span class="ml-1">{{maintenance.label}}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  {{$tc('statusLevel',2)}}:
                </v-col>
                <v-col cols="6">
                      <span :class="nen.color" class="px-4 py-2 rounded-lg ml-0">{{nen.label}}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  {{$t('dimension')}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                      {{capex.dimension}}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  {{$t('commissioningYear')}}:
                </v-col>
                <v-col cols="6" class="font-weight-black">
                      {{capex.commissioningYear}}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  {{$t('compliantRegulations')}}:
                </v-col>
                <v-col cols="6">
                  {{$t('applicableRegulations')}}:
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" class="font-weight-black">
                      <v-switch color="indigo" v-model="capex.compliantRegulations" disabled></v-switch>
                </v-col>
                <v-col cols="6" class="font-weight-black">
                      <v-switch color="indigo" v-model="capex.applicableRegulations" disabled></v-switch>
                </v-col>
              </v-row>
        </v-card-text>
      </v-card>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="4">
              </v-col>
            <v-col cols="8">
              <v-btn block color="indigo accent-3 white--text" @click="showCapex()">{{$t('edit')}}</v-btn>
              </v-col>
          </v-row>
        </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
</template>

<script>
import {mutations} from '../store';
export default {
  name: "Capex",
  props: {
    capex: {
      required: true
    }
  },
  data: () => ({
    icon: mutations.getCapex().icon,
  }),
  computed: {
    maintenance() {

      const list = this.$t("maintenanceLevels").filter(item => {return item.value == this.capex.maintenanceId})
      return list.length > 0 ? list[0] : {}
    },
    nen() {
      const list = this.$t("statusLevels").filter(item => {return item.value == this.capex.nenStatusId})
      return list.length > 0 ? list[0] : {}
    },
    priceFormatter: {
      get: function(){
        return this.capex.price / 100;
      },
      set: function(newPrice){
        this.capex.price =  newPrice * 100;
      }
    }
  },
  methods: {

    showCapex(){
      this.$router.push({name:'capexForm', params: {id: this.capex.id}}).catch(()=>{});
    },

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  span {
    margin-left: 4px
  }
</style>