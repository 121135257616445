<template>
    <v-row >  
        <v-col sm="12" lg="6" class="mx-auto">
        <v-card elevation="0">
            <capex-preview v-bind:capex="capex" class="mx-auto"></capex-preview>
        <v-card-text>
    <v-row>
      <v-col lg="8" sm="12" class="mx-auto">
        <v-btn block  color="indigo accent-3 white--text" @click="replacement()"><v-icon large color="indigo lighten-2" class="pr-6">mdi-file-replace-outline</v-icon>{{$t('replacement')}}</v-btn>
        <p></p>
        <v-btn block color="indigo accent-3 white--text" @click="defect()"><v-icon large color="indigo lighten-2" class="mr-6">mdi-hammer-wrench</v-icon>{{$t('defect')}}</v-btn>
        <p></p>
        <v-btn block color="indigo accent-3 white--text" @click="genericWork()"><v-icon large color="indigo lighten-2" class="mr-6">mdi-account-hard-hat</v-icon>{{$t('genericWork')}}</v-btn>
      </v-col>
    </v-row>
        </v-card-text>
        </v-card>
        </v-col>
    </v-row>
</template>

<script>
import CapexPreview from '../components/CapexPreview.vue'
import {indDB} from '../indexedDB.js'

export default {
  name: "Capex",
  components: {CapexPreview},
  props: {
    id: {
        required: true
    } 
  },
  data: () => ({
      capex: {
      default: function() {
        return {
        name: "",
        description: "",
        location: "",
        clientId: 1,
        maintenance: "",
        resLifespam: "",
        unit: "",
        status: "",
        duration: "",
        price: ""
      }
      }}
  }),

  created () {

    indDB.capexes.get(parseInt(this.id)).then((cap) => {
        this.capex = cap;
      });
  },
  methods: {
    replacement: function(){
      this.$router.push({name: 'replacementNew', params: {id: this.capex.id}})
    },
    defect: function(){
      this.$router.push({name: 'defectNew', params: {id: this.capex.id}})
    },
    genericWork: function(){
      this.$router.push({name: 'genericWorkNew', params: {id: this.capex.id}})
    }
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>